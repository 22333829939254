import React, { useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import { useForm } from 'react-hook-form';
import { FormInputText } from 'components/Common/Forms/FormTextField';
import { GridForm } from 'components/Ui/styled';
import Grid from '@mui/material/Grid';
import { PaperProps } from 'constants';
import { getDialogHeading } from 'utils/commonUtils';
import MDBox from 'components/MDBox';

const PublicProfileDialog = (props) => {
  const { publicProfileDialog, setPublicProfileDialog } = props;

  const defaultValue = {
    username: '',
    userId: '',
    email: '',
    givenName: '',
    familyName: '',
    jobTitle: '',
    vonageExtension: '',
  };

  const useFunction = useForm({
    mode: 'onChange',
    defaultValues: defaultValue,
  });

  const { reset, control } = useFunction;

  useEffect(() => {
    reset(defaultValue);
  }, [defaultValue.title]);

  const handleClose = () => {
    setPublicProfileDialog(false);
    reset(defaultValue);
  };

  return (
    <>
      <Dialog open={publicProfileDialog} PaperProps={PaperProps}>
        {getDialogHeading('Public Profile', handleClose)}
        <GridForm container spacing={2}>
          <Grid item xs={12}></Grid>
          <FormInputText
            name="username"
            type="text"
            control={control}
            label="Username"
            //readOnly
          />
          <FormInputText
            name="givenName"
            type="text"
            control={control}
            label="First Name"
            //readOnly
          />
          <FormInputText
            name="familyName"
            type="text"
            control={control}
            label="Last Name"
            //readOnly
          />
          <FormInputText
            name="jobTitle"
            type="text"
            control={control}
            label="Job Title"
            //readOnly
          />
          <FormInputText
            name="vonageExtension"
            type="text"
            control={control}
            label="Vonage Extension"
            //readOnly
          />
        </GridForm>
        <MDBox pt={2}></MDBox>
      </Dialog>
    </>
  );
};

export default PublicProfileDialog;
