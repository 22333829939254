import React, { useState, useEffect } from 'react';
import { Card } from '@mui/material';
import {
  getDataGridHeading,
  useResetSnackbar,
  useMembersState,
  pageSizeChangeHandler,
  useNotification,
} from 'utils/commonUtils';
import { intialPageSize } from 'constants';
import { getUsersList } from 'layouts/users/services/userApis';
import { useDispatch } from 'react-redux';
import { getLocationData } from 'layouts/inventory/location/services/LocationApi';
import { availableUsers } from 'layouts/groups/store/availableUsersSlice';
import { TypographyLink } from 'components/Ui/styled';
import MembersDialog from 'layouts/groups/list/MembersDialog';
import DeltaDataGrid from 'utils/CommonDataGrid/CommonDataGrid';

const LocationUserList = () => {
  useResetSnackbar();
  const dispatch = useDispatch();
  const { NotificationPopup, handleErrorResponse } = useNotification();

  const { loading, membersDialog, handleMembers, setMembersDialog } =
    useMembersState();
  const [locationUserList, setLocationUserList] = useState({
    isLoading: false,
    rows: [],
    pageSize: intialPageSize,
  });

  useEffect(() => {
    fetchLocationList();
    getAvailableUsersList();
  }, []);

  const getAvailableUsersList = async () => {
    const listOfAvaoilableUser = await getUsersList();
    dispatch(availableUsers(listOfAvaoilableUser));
  };

  const fetchLocationList = async () => {
    try {
      setLocationUserList({ ...locationUserList, isLoading: true });
      const locationsList = await getLocationData();
      setLocationUserList({ ...locationUserList, isLoading: false });
      setLocationUserList((previous) => ({
        ...previous,
        rows: locationsList?.items?.map((item, index) => ({
          ...item,
          id: item.id,
          location: item.location,
          users: item.assignedUsers.length,
          assignedUsers: item.assignedUsers,
        })),
      }));
    } catch (error) {
      setLocationUserList({ ...locationUserList, isLoading: false });
      handleErrorResponse(
        error?.response?.data?.message ||
          'An error occurred while fetching Location data'
      );
    }
  };

  const handleAssignedUsers = handleMembers('Assigned Users');

  const columns = [
    { headerName: 'Location', field: 'location', flex: 1 },
    {
      headerName: 'Users',
      field: 'username',
      renderCell: (params) => {
        return (
          <TypographyLink
            variant="h6"
            onClick={() => handleAssignedUsers(params)}
          >
            {params.row.users}
          </TypographyLink>
        );
      },
      align: 'center',
      sortable: false,
      disableColumnMenu: true,
      flex: 1,
    },
  ];

  const pageSizeChange = pageSizeChangeHandler(setLocationUserList);

  return (
    <>
      <Card>
        {getDataGridHeading('Assign users to locations')}
        <DeltaDataGrid
          listData={locationUserList}
          columns={columns}
          setListData={pageSizeChange}
        />
      </Card>
      <MembersDialog
        setMembersDialog={setMembersDialog}
        membersDialog={membersDialog}
        fetchGroups={fetchLocationList}
        loading={loading}
      />
      <NotificationPopup />
    </>
  );
};

export default LocationUserList;
