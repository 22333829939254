import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import { HeadingWrapperBox } from 'layouts/inventory/Inventory.styled';
import { useEffect, useState } from 'react';
import { deleteCalendar, getUsers } from '../services/CalendarApi';
import CalendarDialog from './CalendarDialog';
import CalenderAction from './CalenderAction';
import { useSelector, useDispatch } from 'react-redux';
import { updateCalendarData } from '../store/AdminSlice';
import CustomizedSnackbars from 'components/snackbar/Snackbar';
import CalenderPermissionDialog from './CalenderPermissionDialog';
import { getCalendarsForAdmin } from 'examples/Calendar/services/calendarsApi';
import { getCalendarsData } from 'examples/Calendar/store/calendarsSlice';
import {
  intialPageSize,
  deltaAcademyCalendar,
  deltaAcademyCalendarErrorMessage,
} from 'constants';
import CommonDeleteDialog from 'components/Common/CommonDeleteDialog/CommonDeleteDialog';
import {
  useResetSnackbar,
  useNotification,
  pageSizeChangeHandler,
} from 'utils/commonUtils';
import CalendarAccessDialog from './CalendarAccessDialog';
import DeltaDataGrid from 'utils/CommonDataGrid/CommonDataGrid';

export const CalendarAdmin = () => {
  useResetSnackbar();
  const dispatch = useDispatch();
  
  const {
    setOpenNotification,
    setNotificationMessage,
    NotificationPopup,
    handleErrorResponse,
  } = useNotification();

  const [openCalendar, setOpenCalendar] = useState(false);
  const [openCalendarAccessDialog, setOpenCalendarAccessDialog] =
    useState(false);
  const [openPermission, setOpenPermission] = useState(false);
  const [permissionCalendarId, setPermissionCalendarId] = useState(null);
  const [isPermissionCalendarLoading, setIsPermissionCalendarLoading] =
    useState(false);
  const [usersReferencedata, setUsersReferencedata] = useState([]);
  const [openInventoryDeleteDialog, setOpenInventoryDeleteDialog] =
    useState(false);
  const [calendarList, setCalendarList] = useState({
    isLoading: false,
    rows: [],
    pageSize: intialPageSize,
  });

  const Calendars = useSelector((state) => state.CalenderAdmin);

  const { name, calendarId } = Calendars;

  useEffect(() => {
    fetchCalendarData();
  }, []);

  const fetchCalendarData = async () => {
    try {
      setCalendarList((previous) => ({
        ...previous,
        isLoading: true,
      }));
      const calendarList = await getCalendarsForAdmin();
      const calendarData = calendarList.calendars;

      setCalendarList((previous) => ({
        ...previous,
        rows: calendarData?.map((item, index) => ({
          ...item,
          id: index,
        })),
        isLoading: false,
      }));
      const usersData = await getUsers();
      setUsersReferencedata(usersData);
      dispatch(getCalendarsData(calendarList));
    } catch (error) {
      setCalendarList((previous) => ({
        ...previous,
        isLoading: false,
      }));
      handleErrorResponse(error?.response?.data?.message);
    }
  };

  const editClickHandler = (params) => {
    const { row } = params;
    const { name, calendarId } = row;

    const payload = {
      name,
      calendarId,
    };

    dispatch(updateCalendarData(payload));
    setOpenCalendar(true);
  };

  const deleteClickHandler = (params) => {
    const { row } = params;
    const { name, calendarId } = row;

    const payload = {
      name,
      calendarId,
    };
    if (deltaAcademyCalendar === calendarId) {
      setOpenNotification(true);
      setNotificationMessage(deltaAcademyCalendarErrorMessage);
    } else {
      dispatch(updateCalendarData(payload));
      setOpenInventoryDeleteDialog(true);
    }
  };

  const calendarAccess = async (params) => {
    const { row } = params;
    setPermissionCalendarId(row.calendarId);
    setIsPermissionCalendarLoading(true);
    setOpenCalendarAccessDialog(true);
  };

  const renderActionItems = (params) => {
    return (
      <CalenderAction
        params={params}
        updateCalendar={editClickHandler}
        deleteCalendar={deleteClickHandler}
        calendarAccess={calendarAccess}
      />
    );
  };

  const setPermmission = () => {
    setOpenPermission(true);
  };

  const addcalendar = () => {
    const payload = {
      newCalendar: true,
      name: null,
      calendarId,
      permissions: [],
    };
    dispatch(updateCalendarData(payload));
    setOpenCalendar(true);
  };

  const postParams = {
    calendarId,
  };

  const columns = [
    {
      field: 'action',
      headerName: 'Action',
      sortable: false,
      width: 120,
      align: 'center',
      renderCell: (params) =>
        renderActionItems(
          params,
          editClickHandler,
          deleteClickHandler,
          calendarAccess
        ),
    },
    { headerName: 'Calendar Name', field: 'name', flex: 1 },
  ];

  const pageSizeChange = pageSizeChangeHandler(setCalendarList);

  return (
    <DashboardLayout>
      <CustomizedSnackbars />
      <DashboardNavbar />
      <>
        <MDBox>
          <Card>
            <HeadingWrapperBox>
              <MDTypography variant="h5" fontWeight="medium">
                Calendars
              </MDTypography>
              <Button variant="text" onClick={() => setPermmission()}>
                set permission
              </Button>
              <Button variant="text" onClick={() => addcalendar()}>
                New Calendar
              </Button>
            </HeadingWrapperBox>
            <DeltaDataGrid
              listData={calendarList}
              columns={columns}
              setListData={pageSizeChange}
            />
            <CalendarDialog
              fetchCalendarData={fetchCalendarData}
              openCalendar={openCalendar}
              setOpenCalendar={setOpenCalendar}
              usersReferencedata={usersReferencedata}
            />
            <CalendarAccessDialog
              open={openCalendarAccessDialog}
              setOpen={setOpenCalendarAccessDialog}
              permissionCalendarId={permissionCalendarId}
              setPermissionCalendarId={setPermissionCalendarId}
              isPermissionCalendarLoading={isPermissionCalendarLoading}
              setIsPermissionCalendarLoading={setIsPermissionCalendarLoading}
            />
            <CommonDeleteDialog
              openDeleteDialog={openInventoryDeleteDialog}
              setOpenDeleteDialog={setOpenInventoryDeleteDialog}
              contentText={
                'Are you sure you want to delete this calendar ' + name + '?'
              }
              fetchData={fetchCalendarData}
              snakBarName="Calendar"
              commonDeleteApi={deleteCalendar}
              commonDeleteId={postParams}
            />
          </Card>
        </MDBox>
      </>
      <CalenderPermissionDialog
        open={openPermission}
        setOpen={setOpenPermission}
        usersReferencedata={usersReferencedata}
      />
      <NotificationPopup />
    </DashboardLayout>
  );
};
