import { renderPublicProfileAction } from "./PublicProfileAction";

export const publicProfileColumns = (handleViewPublicProfile) => [
  {
    field: 'action',
    headerName: 'Action',
    sortable: false,
    disableColumnMenu: true,
    width: 80,
    renderCell: (params) =>
      renderPublicProfileAction(params, handleViewPublicProfile),
  },
  { headerName: 'Username', field: 'username', width: 250 },
  { headerName: 'User Id', field: 'userId', flex: 1, hide: true },
  { headerName: 'Email', field: 'email', width: 250 },
  { headerName: 'First Name', field: 'givenName', flex: 1 },
  { headerName: 'Last Name', field: 'familyName', flex: 1 },
  { headerName: 'Job Title', field: 'jobTitle', flex: 1 },
  { headerName: 'Vonage Extension', field: 'vonageExtension', flex: 1 },
];

export const getPublicProfileListData = (publicProfileListData) => {
  const items = publicProfileListData?.profiles;
  if (!items || items.length === 0) {
    return [];
  }
  return items.map(
    ({
      username,
      userId,
      email,
      givenName,
      familyName,
      jobTitle,
      vonageExtension,
    }) => ({
      id: userId,
      username,
      email,
      givenName,
      familyName,
      jobTitle,
      vonageExtension,
    })
  );
};
