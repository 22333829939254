import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import Grid from '@mui/material/Grid';
import { GridWrapper } from 'components/Ui/styled';
import {
  getButtonSpinner,
  useNotification,
  dropDownOptions,
  pageSizeChangeHandler,
  onRedirect,
  renderSelectedValue,
} from 'utils/commonUtils';
import FormInputDropdown from 'components/Common/Forms/FormInputDropDown';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  setStudentType,
  setInstructor,
  setCourse,
  setGradeLevel,
  setSchoolYears,
  setTestCategory,
  setTestSubcategories,
  setProficiencyLevel,
  setTestSubcategoriesList,
  setByCategory,
  setIsRawResults,
  resetReportFilters,
} from 'layouts/reports/store/reportsFilterSlice/reportsFilterSlice';
import {
  postScores,
  exportScores,
} from 'layouts/studentManagement/services/studentProfileApi';
import {
  getReportsColumns,
  getReportsRow,
} from '../TestingReports/ReportsDataGrid';
import { booleanOptions } from 'constants';
import ReportsDialog from '../TestingReports/ReportsDialog';
import { setReportsForm } from 'layouts/reports/store/reportsSlice/reportsSlice';
import DeltaDataGrid from 'utils/CommonDataGrid/CommonDataGrid';
import { getSearchStudentDetails } from 'layouts/students/service/studentsApi';
import { getStudentData } from 'layouts/students/store/studentDetailsSlice';
import { rowsPerPage2 } from 'constants';

const ReportFilters = (props) => {
  const { reportsList, setReportsList, setIsLoading } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [reportsColumns, setReportsColumns] = useState([]);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [isExportButtonLoading, setIsExportButtonLoading] = useState(false);
  const [openReportsDialog, setOpenReportsDialog] = useState(false);

  const {
    setOpenNotification,
    setNotificationMessage,
    NotificationPopup,
    handleErrorResponse,
  } = useNotification();

  const scoresOptions = useSelector((state) => state.scoresOptions);

  const {
    allStudentTypes,
    allInstructors,
    allCourseNames,
    allGradeLevels,
    testCategories,
    schoolYears,
    testSubcategories,
    filterFieldsByCategory,
  } = scoresOptions || {};

  const reportsFilter = useSelector((state) => state.reportsFilter);

  const defaultValue = {
    studentType: reportsFilter?.studentType || '',
    instructor: reportsFilter?.instructor || '',
    course: reportsFilter?.course || '',
    gradeLevel: reportsFilter?.gradeLevel || '',
    schoolYears: reportsFilter?.schoolYears || [],
    testCategory: reportsFilter?.testCategory || '',
    testSubcategories: reportsFilter?.testSubcategories || [],
    filterFieldsByCategory:
      reportsFilter?.filterOptions?.proficiencyLevel || [],
    isRawResults: reportsFilter?.isRawResults || '',
  };

  const useFunction = useForm({
    mode: 'onChange',
    defaultValues: defaultValue,
  });

  const { control, reset, register } = useFunction;

  useEffect(() => {
    reset(defaultValue);
  }, [
    defaultValue.studentType,
    defaultValue.instructor,
    defaultValue.course,
    defaultValue.gradeLevel,
    defaultValue.schoolYears,
    defaultValue.testCategory,
    defaultValue.testSubcategories,
    defaultValue.filterFieldsByCategory,
    defaultValue.isRawResults,
  ]);

  const handleSchoolYears = (e) => {
    dispatch(
      setSchoolYears(
        typeof e.target.value === 'string'
          ? e.target.value.split(',')
          : e.target.value
      )
    );
  };

  const handleTestSubcategories = (e) => {
    dispatch(
      setTestSubcategories(
        typeof e.target.value === 'string'
          ? e.target.value.split(',')
          : e.target.value
      )
    );
  };

  const handleFieldsByCategory = (e) => {
    dispatch(
      setProficiencyLevel(
        typeof e.target.value === 'string'
          ? e.target.value.split(',')
          : e.target.value
      )
    );
  };

  const handleTestCategory = (e) => {
    dispatch(setTestCategory(e.target.value));
    dispatch(setTestSubcategoriesList(testSubcategories[e.target.value]));
    dispatch(setByCategory(filterFieldsByCategory[e.target.value]));
    dispatch(setTestSubcategories([]));
    dispatch(setProficiencyLevel([]));
  };

  const viewReport = (params) => {
    const { row } = params;
    setOpenReportsDialog(true);
    dispatch(setReportsForm(row));
  };

  const generatePayload = () => {
    const byCategory = reportsFilter?.byCategory?.[0]?.name || '';
    return {
      course: reportsFilter?.course,
      filterOptions: {
        [byCategory]: reportsFilter?.filterOptions?.proficiencyLevel,
      },
      gradeLevel: reportsFilter?.gradeLevel,
      instructor: reportsFilter?.instructor,
      schoolYears: reportsFilter?.schoolYears,
      studentNumbers: [],
      studentType: reportsFilter?.studentType,
      testCategory: reportsFilter?.testCategory,
      testSubcategories: reportsFilter?.testSubcategories,
      returnRawResults: reportsFilter?.isRawResults,
      isRawResults: reportsFilter?.isRawResults,
    };
  };

  const handleStudentRedirect = (params) => {
    const { row } = params;
    const { 'Student Number': studentNumber, StudentID: StudentIDAlt } = row;
    const studentId = studentNumber || StudentIDAlt;
    onRedirect(
      setIsLoading,
      getSearchStudentDetails,
      studentId,
      dispatch,
      getStudentData,
      navigate,
      '/reports/testing-reports/student-profile',
      setOpenNotification,
      setNotificationMessage
    );
  };

  const handleGenerateReport = async () => {
    try {
      setIsButtonLoading(true);
      const scores = await postScores(generatePayload());
      setIsButtonLoading(false);
      const columns = getReportsColumns(
        scores,
        viewReport,
        handleStudentRedirect
      );
      setReportsColumns(columns);
      setReportsList({ ...reportsList, isLoading: false });
      const rowData = getReportsRow(scores);
      setReportsList((prev) => ({
        ...prev,
        rows: rowData,
      }));
    } catch (error) {
      handleErrorResponse(error?.response?.data?.message);
      setReportsList({ ...reportsList, isLoading: false });
      setIsButtonLoading(false);
    }
  };

  const handleExportScores = async () => {
    try {
      setIsExportButtonLoading(true);
      const scores = await exportScores(generatePayload());
      const blob = new Blob([scores], { type: 'text/csv' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'report scores.csv');
      document.body.appendChild(link);
      link.click();
      setIsExportButtonLoading(false);
    } catch (error) {
      handleErrorResponse(error?.response?.data?.message);
      setReportsList({ ...reportsList, isLoading: false });
      setIsExportButtonLoading(false);
    }
  };

  const handleClearFilters = () => {
    dispatch(resetReportFilters());
    setReportsColumns([]);
    setReportsList({
      ...reportsList,
      rows: [],
    });
  };

  const pageSizeChange = pageSizeChangeHandler(setReportsList);

  const pinnedColumns = {
    left: ['action', 'name'],
  };

  return (
    <>
      <GridWrapper container spacing={2}>
        <Grid item xs={12} sm={6} md={3}>
          <FormInputDropdown
            name="studentType"
            control={control}
            options={dropDownOptions(allStudentTypes)}
            defaultValue={defaultValue?.studentType}
            label="Student Type"
            register={register('studentType', {
              onChange: (e) => dispatch(setStudentType(e.target.value)),
            })}
          />
          <FormInputDropdown
            name="schoolYears"
            control={control}
            multiple={true}
            checked={defaultValue.schoolYears}
            renderValue={renderSelectedValue}
            options={dropDownOptions(schoolYears)}
            label="School Years"
            register={register('schoolYears', {
              onChange: (e) => handleSchoolYears(e),
            })}
          />
          <FormInputDropdown
            name="isRawResults"
            control={control}
            options={booleanOptions}
            defaultValue={defaultValue.isRawResults}
            label="Raw Result"
            register={register('isRawResults', {
              onChange: (e) => dispatch(setIsRawResults(e.target.value)),
            })}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <FormInputDropdown
            name="instructor"
            control={control}
            options={dropDownOptions(allInstructors)}
            defaultValue={defaultValue?.instructor}
            label="Instructor"
            register={register('instructor', {
              onChange: (e) => dispatch(setInstructor(e.target.value)),
            })}
            filter
          />
          <FormInputDropdown
            name="testCategory"
            control={control}
            options={dropDownOptions(testCategories)}
            defaultValue={defaultValue?.testCategory}
            label="Test Category"
            register={register('testCategory', {
              onChange: (e) => handleTestCategory(e),
            })}
          />
          {getButtonSpinner(false, handleClearFilters, 'Clear Filters')}
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <FormInputDropdown
            name="course"
            control={control}
            options={dropDownOptions(allCourseNames)}
            defaultValue={defaultValue?.course}
            label="Course"
            register={register('course', {
              onChange: (e) => dispatch(setCourse(e.target.value)),
            })}
            filter
          />
          <FormInputDropdown
            name="testSubcategories"
            control={control}
            multiple={true}
            checked={defaultValue.testSubcategories}
            renderValue={renderSelectedValue}
            options={dropDownOptions(reportsFilter?.testSubcategoriesList)}
            label="Sub Categories"
            register={register('testSubcategories', {
              onChange: (e) => handleTestSubcategories(e),
            })}
          />
          {getButtonSpinner(
            isExportButtonLoading,
            handleExportScores,
            'Export Scores'
          )}
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <FormInputDropdown
            name="gradeLevel"
            control={control}
            options={dropDownOptions(allGradeLevels)}
            defaultValue={defaultValue?.gradeLevel}
            label="Grade Level"
            register={register('gradeLevel', {
              onChange: (e) => dispatch(setGradeLevel(e.target.value)),
            })}
            filter
          />
          <FormInputDropdown
            name="filterFieldsByCategory"
            control={control}
            multiple={true}
            checked={defaultValue.filterFieldsByCategory}
            renderValue={renderSelectedValue}
            options={dropDownOptions(reportsFilter?.byCategory?.[0]?.values)}
            label={reportsFilter?.byCategory?.[0]?.name || 'By Category'}
            register={register('filterFieldsByCategory', {
              onChange: (e) => handleFieldsByCategory(e),
            })}
          />
          {getButtonSpinner(
            isButtonLoading,
            handleGenerateReport,
            'Generate Report'
          )}
        </Grid>
      </GridWrapper>
      <DeltaDataGrid
        listData={reportsList}
        columns={reportsColumns}
        setListData={pageSizeChange}
        rowsPerPage2={rowsPerPage2}
        initialState={{
          pinnedColumns: pinnedColumns,
        }}
      />
      <ReportsDialog
        openReportsDialog={openReportsDialog}
        setOpenReportsDialog={setOpenReportsDialog}
      />
      <NotificationPopup />
    </>
  );
};

ReportFilters.defaultProps = {
  allStudentTypes: [],
  allInstructors: [],
  allCourseNames: [],
  allGradeLevels: [],
  testCategories: [],
  schoolYears: [],
  testSubcategories: {},
  filterFieldsByCategory: {},
};

ReportFilters.propTypes = {
  allStudentTypes: PropTypes.array,
  allInstructors: PropTypes.array,
  allCourseNames: PropTypes.array,
  allGradeLevels: PropTypes.array,
  testCategories: PropTypes.array,
  testSubcategories: PropTypes.object,
  filterFieldsByCategory: PropTypes.object,
};

export default ReportFilters;
